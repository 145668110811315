import Core from "./Core";

export default class DeliverectWidget extends Core {
  orderNow(data = {}) {
    this.showOrderSystem(data);
  }

  launchWidget() {
    this.launcher.classList.add("deliverect-visible");
    this.greetings.classList.add("deliverect-visible");
  }

  showOrderSystem() {
    window.location.href = this.config.orderLink;

    /**
     * The old behaviour where on desktop whe slide in a
     * drawer with the ordering system has been disabled for the
     * time being - for more details look a the following link
     *
     * https://deliverect.atlassian.net/browse/SO-2697
     */
    // if (this._isMobile()) {
    //   window.location.href = this.config.orderLink;
    //   return;
    // }

    // const payload = {
    //   salt: process.env.VUE_APP_EVENT_SALT,
    //   event: "release_analytics",
    // };

    // this.orderSystem.contentWindow.postMessage(payload, "*");

    // this.orderSystem.classList.add("deliverect-visible");
    // this.backdrop.classList.add("deliverect-visible");
    // this.orderButton.classList.remove("deliverect-visible");
    // this.launcher.classList.remove("deliverect-visible");
    // this.greetings.classList.remove("deliverect-visible");
    // document.body.classList.add("deliverect-open");
  }

  closeOrderSystem() {
    this.orderSystem.classList.remove("deliverect-visible");
    this.backdrop.classList.remove("deliverect-visible");
    document.body.classList.remove("deliverect-open");

    this.showOrderButton();
  }

  closeGreetings() {
    this.launcher.classList.remove("deliverect-visible");
    this.greetings.classList.remove("deliverect-visible");

    this.showOrderButton();
  }

  showOrderButton() {
    if (this.state.type !== "compact") {
      this.orderButton.classList.add("deliverect-visible");
    }
  }

  setGreetingsHeight(data = {}) {
    this.greetings.setAttribute("style", `height: ${data.height + 7}px;`);
  }
}
