import DeliverectWidget from "./DeliverectWidget";

export const widgetInit = async (slug) => {
  const url = `${process.env.VUE_APP_API_LINK}/storefront/v2/businesses/${slug}/widget`;

  try {
    const response = await fetch(url);

    if (!response.ok) {
      throw new Error(`Fetch error: ${response.status}`);
    }

    const data = await response.json();

    const state = {
      buttonText: data.button_text,
      launcherIcon: data.launcher_icon,
      message: data.message,
      textColor: data.text_color,
      themeColor: data.theme_color,
      title: data.title,
      type: data.type,
      orderingLink: data.ordering_link,
    };

    window.DeliverectWidget = new DeliverectWidget(slug, state);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(`[DeliverectWidget]: ${error.message}`);
  }
};
